<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>聊天管理</el-breadcrumb-item>
      <el-breadcrumb-item>设置欢迎词</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <el-form label-width="120px" style="width:600px">
        <el-form-item label="欢迎词内容：">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入欢迎词内容"
            resize="none"
            v-model="content"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="text-align:right;">
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    this.axios
      .get(
        this.serveurl.serve + "/api/UserExtend/get_new_user_welcoming_speech"
      )
      .then((res) => {
        if (res.data.code == 1) {
          this.content = res.data.result;
        }
      });
  },
  methods: {
    onSubmit() {
      if (this.content == "") {
        this.$message({
          type: "error",
          message: "请输入欢迎词",
        });
        return;
      }
      this.axios
        .post(
          this.serveurl.serve +
            "/api/UserExtend/edit_new_user_welcoming_speech",
          this.qs.stringify({ content: this.content })
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>